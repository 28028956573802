import { Project } from "../types";

export const projects: Project[] = [
  {
    name: "Random Tree Generator",
    blurb:
      "Randomly generated tree. Repulsive &ldquo;charge&rdquo; on nodes for emergent flattening.",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/assignment10/",
    git: "https://github.com/jxjj/exd-a10",
    thumbnail: "img/thumb-exp-randomtree-800x600.png",
    category: "Experiments",
    tags: ["p5js"],
  },
  {
    name: "Gravity Sandbox",
    blurb: "Simple simulation of particles",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/assignment7/",
    git: "https://github.com/jxjj/gravity-sandbox-experiment",
    thumbnail: "img/thumb-exp-antigravity-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "Wave Equation 6",
    blurb: "Another exploration of the wave equation",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/exercise6/",
    git: "https://github.com/jxjj/exd-exercise6",
    thumbnail: "img/thumb-exp-waves2-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "Harmonigraph",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/assignment5b/",
    git: "https://github.com/jxjj/exd-assignment5b",
    blurb: "Graphical music experiment. A bit headache inducing.",
    thumbnail: "img/thumb-exp-harmonigraph-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "Time System",
    blurb: "A complicated clock",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/assignment4/",
    git: "https://github.com/jxjj/exd-assignment4",
    thumbnail: "img/thumb-exp-time4a-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "Evolving Letters",
    blurb: "Using randomness and selection to evolve letterforms over time",
    url: "https://jjohnson136.studio.mcad.edu/exd-projects/assignment3/",
    git: "https://github.com/jxjj/exd-assignment3",
    thumbnail: "img/thumb-exp-evolvingletters-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "Wave Equation 2a",
    blurb: "An exploration of the wave equation",
    url: "https://codepen.io/johnsojr/full/JmymEZ/",
    git: "https://codepen.io/johnsojr/pen/JmymEZ",
    thumbnail: "img/thumb-exp-waves-800x600.png",
    category: "Experiments",
    tags: "p5js",
  },
  {
    name: "HyperQuiz",
    blurb: `A simple trivia game using <a href="https://github.com/jorgebucaran/hyperapp">Hyperapp</a>`,
    url: "https://hyperquiz-app.netlify.com/",
    git: "https://github.com/jxjj/hyperquiz",
    thumbnail: "img/thumb-hyperquiz-800x600.png",
    date: new Date("2018-10-01"),
    category: "Side Projects",
    tags: ["hyperapp", "js"],
  },
  {
    name: "Knitting Counter",
    date: new Date("2017-07-01"),
    url: "http://knittingcounter.com",
    blurb: `A stitch counter using <a href="https://www.meteor.com/">MeteorJS</a>`,
    thumbnail: "img/thumb-knittingcounter-800x600.png",
    category: "Side Projects",
    tags: ["react", "meteorjs", "node", "js"],
  },
  {
    name: "SISter",
    date: new Date("2017-09-17"),
    client: "Minneapolis College of Art and Design",
    blurb:
      "A simplified interface to MCAD's Student Information System (SIS), created with React and Meteor",
    thumbnail: "img/thumb-sister-800x600.png",
    category: "Side Projects",
    tags: ["react", "meteor", "node", "js"],
  },
  {
    name: "Best Banner Contest",
    client: "Minneapolis College of Art and Design",
    url: "http://best-banner.mcad.education",
    blurb:
      "Annual contest where faculty, staff, and students vote for their favorite course banners. App created using MeteorJS.",
    thumbnail: "img/thumb-bestbanner2018-800x600.png",
    category: "Side Projects",
    tags: ["react", "meteor", "js"],
  },
  {
    name: "Sustainable Design Blog",
    client: "Minneapolis College of Art and Design",
    date: new Date("2014-12-01"),
    url: "http://mcadsustainabledesign.com",
    blurb:
      "A blog on sustainability and Sustainable Design created for MCAD. Custom WordPress theme.",
    thumbnail: "img/thumb-mcadsdo-800x600.jpg",
    category: "Work",
    tags: ["WordPress", "php", "js"],
  },
  {
    name: "RxArtisans",
    client: "RxArtisans.com",
    url: "http://rxartisans.com",
    date: new Date("2017-09-01"),
    blurb:
      "Landing page for a compounding pharmacy, creating custom pharmaceuticals for doctors and their patients. Plain ole HTML, CSS, and JS",
    thumbnail: "img/thumb-rxartisans-800x600.png",
    tags: ["HTML", "CSS", "JS"],
    category: "Side Projects",
  },
  {
    name: "ProgStar Research",
    client: "Foundation Fighting Blindness",
    date: new Date("2014-02-10"),
    url: "http://progstar.org",
    blurb:
      "News and research about the Stargaardt disease. Custom WordPress theme.",
    thumbnail: "img/thumb-progstarExt-800x600.jpg",
    tags: ["WordPress", "php", "js"],
    category: "Work",
  },
  {
    name: "Public Art Review",
    client: "Forecast Public Art",
    url: "http://forecastpublicart.org",
    date: new Date("2013-02-01"),
    blurb:
      "Forecast Public Art publishes Public Art Review biannually. Custom WordPress theme.",
    thumbnail: "img/thumb-par-800x600.jpg",
    category: "Work",
  },
];
